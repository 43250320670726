<template>
  <v-container fluid class="d-flex flex-column justify-center align-center fill-height main-container">
    <div class="d-flex flex-column justify-center align-center">
      <v-img src="/logo-blanco.svg" width="80px" max-width="80px" max-height="80px" height="80px"></v-img>
      <v-card class="login-card pa-9" elevation="0">
        <div class="login-card__container mt-1 px-5">
          <p class="login-card__title">Bienvenido</p>

          <v-form ref="form">
            <p class="login-input__label">Username</p>
            <v-text-field :rules="[rules.required]" class="login-input__input" outlined placeholder="user@cobi.pe"
                          v-model="username"></v-text-field>

            <div class="d-flex justify-space-between">
              <p class="login-input__label">Contraseña</p>
              <div>
                <v-icon color="white" size="24px" @click="showPassword = !showPassword">{{
                    !showPassword ? 'mdi-eye' : 'mdi-eye-off'
                  }}
                </v-icon>
                Ocultar
              </div>
            </div>
            <v-text-field :rules="[rules.required]" v-model="password" :type="showPassword?'text': 'password'"
                          class="login-input__input" outlined
                          placeholder="12345">
            </v-text-field>

            <v-btn @click="doLogin" :loading="loading" elevation="0" rounded height="64px" block color="#363740"
                   class="login-button" dark>
              Ingresar
            </v-btn>
          </v-form>
          <div class="mt-4">Al ingresar, usted acepta nuestros <a href="">Terminos y Condiciones</a> de uso</div>

          <div class="d-flex justify-space-between mt-12">
            <a style="max-width: 184px;" href="">¿Otros problemas para ingresar?</a>
            <a style="max-width: 184px;" href="">Forget your password</a>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from '@/utils/rules'

export default {
  name: 'Login.vue',
  data () {
    return {
      showPassword: false,
      username: '',
      password: '',
      loading: false,
      rules: {
        required
      }
    }
  },
  methods: {
    ...mapActions(['login']),
    async doLogin () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.login({ username: this.username, password: this.password })
          this.$router.push({ name: 'Dispositivos' })
          this.loading = false
        } catch (e) {
          this.loading = false
          let message = 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos'
          if (e.message) {
            message = e.message
          }
          this.$root.$confirm({ message, type: 'alert' })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-container {
  background: radial-gradient(circle, rgba(12, 106, 171, 1) 0%, rgba(17, 23, 24, 1) 75%);
}

.login-card {
  border: 1px solid rgba(100, 100, 100, 0.5);
  border-radius: 24px;
  max-width: 600px;
  margin: 20px auto auto auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: white;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 24px;

  a {
    color: white;
  }

  &__container {
    margin: auto;
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: white;
    text-align: center;
  }
}

.login-input {
  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    margin-bottom: 11px;
  }
}

.login-button {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: none;
}

.login-input__input ::v-deep .v-input__slot fieldset {
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 12px;
}

.login-input__input ::v-deep .v-input__slot input {
  color: white;
}

.login-button ::v-deep {
  border-radius: 40px;
}

</style>
